import logo from '../images/logo.png';
import styles from '../components/NavBar.module.css';
import zap from '../images/zap.png';
import { useState } from 'react';

const NavBar = () => {

    const goToElementById = (id: string, offset: number) => {
        const element = document.getElementById(id);
        if (element) {
          const pos = element.getBoundingClientRect().top + window.scrollY + offset;
          window.scrollTo({ top: pos, behavior: 'smooth' });
        }
    }

    const [navButton, setNavButton] = useState("navbar-toggler collapsed");
    const [ariaExpanded, setAriaExpanded] = useState(false);
    const [navCollapse, setNavCollapse] = useState("navbar-collapse collapse");

    const closeState = () => {
        setNavButton("navbar-toggler collapsed");
        setAriaExpanded(false);
        setNavCollapse("navbar-collapse collapse");
    }

    const configuraState = (value: string) => {

        if (value === '') {
          return value
        } else if (value === 'filtro1') {
            if (ariaExpanded === true) {
                closeState();
            } else {
                setNavButton("navbar-toggler");
                setAriaExpanded(true);
                setNavCollapse("navbar-collapse collapse show");
            }
        }
    }

    return (
        <nav className={`navbar navbar-expand-lg fixed-top navbar-light bg-light`}>
            <div className="container-fluid">
                <img className={`${styles.navbarImageLogo}`}  src={logo} alt='logo' />
                {/* <div style={{ display: 'block', justifyItems: 'center', border: '0px', margin: '0px', padding: '0px' }}>
                    <span className={`${styles.navbarNameResponsive}`}>
                        Ana Caroline Almeida
                    </span>
                </div> */}
                <div>
                    <img className={`${styles.navbarImageZapResponsive}`} src={zap} alt='zapCel' />
                    <span className={`${styles.navbarCelZapResponsive}`}>
                        (71) 99188-7375
                    </span>
                </div>
                <button className={navButton} type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded={ariaExpanded} aria-label="Toggle navigation" onClick={() => configuraState('filtro1')}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={navCollapse} id="navbarColor03">
                <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                        <button type="button" className="btn btn-link" onClick={() => goToElementById('home', -120)} style={{ color: 'black', textDecoration: 'none' }}>
                            Home
                        </button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="btn btn-link" onClick={() => goToElementById('sobreMim', -120)} style={{ color: '#737373', textDecoration: 'none'}}>
                            Sobre Mim
                        </button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="btn btn-link" onClick={() => goToElementById('especialidades', -120)} style={{ color: '#737373', textDecoration: 'none' }}>
                            Especialidades
                        </button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="btn btn-link" onClick={() => goToElementById('duvidas', -120)} style={{ color: '#737373', textDecoration: 'none' }}>
                            Dúvidas e Perguntas Frequentes
                        </button>
                    </li>
                    <li className="nav-item">
                        <button type="button" className="btn btn-link" onClick={() => goToElementById('contato', -120)} style={{ color: '#737373', textDecoration: 'none' }}>
                            Contatos
                        </button>
                    </li>
                </ul>
                    <img className={`${styles.navbarImageZap}`} src={zap} alt='zap' />
                    <span className={`${styles.navbarCelZap}`}>
                        <strong>(71) 99188-7375 </strong>
                    </span>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;



