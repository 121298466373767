import { Grid } from "@material-ui/core";
import styles from '../components/SobreMim.module.css';

const SobreMim = () => {

    return (
        <>
            <Grid container spacing={4} style={{ marginBottom: '-1rem', backgroundColor: 'white' }}>
                <Grid container item spacing={4}>
                    <Grid item xs={12}>
                        <h2 style={{ color: '#737373', textAlign: 'justify' }}>Sobre Mim</h2>
                        <p style={{ color: '#737373', textAlign: 'justify' }}><strong>Ana Caroline Correia de Almeida de Jesus</strong></p>
                        <p style={{ color: '#737373', textAlign: 'justify' }}><small>Psicóloga Clínica e Perinatal (CRP ​03/11502).</small></p>
                        <p style={{ color: '#737373', textAlign: 'justify' }}>Eu sou psicóloga e psicoterapeuta, com especialização em Saúde da Família e Psicologia Perinatal.</p>
                        <p style={{ color: '#737373', textAlign: 'justify' }}>Atuo como psicóloga clínica realizando atendimento individual para adolescentes e adultos na modalidade online, para todo o Brasil e residentes no Exterior, atendendo as mais diversas demandas, como transtornos ansiosos, depressão, fobias, conflitos profissionais, transtorno bipolar, transtornos alimentares, traumas psicológicos, dentre outros.</p>
                        <p style={{ color: '#737373', textAlign: 'justify' }}>Durante o processo de terapia, são respeitados os objetivos de cada paciente, sendo um processo único e personalizado, que visa desenvolver as habilidades necessárias para que ele possa superar os seus medos, dificuldades e dores que o esteja limitando emocionalmente, bem como promover o autoconhecimento e fortalecer a autoestima, para que assim possa ressignificar sua vida e restabelecer seu equilíbrio psicológico.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <figure className="text-end">
                        <blockquote className="blockquote">
                            <p className={`mb-0 ${styles.sobreMimFrase}`} >“A terapia começa antes mesmo do terapeuta. Quando a pessoa percebe</p>
                            <p className={`mb-0 ${styles.sobreMimFrase}`}>que algo precisa ser mudado, já é parte do processo.”</p>
                            <p className={`mb-0 ${styles.sobreMimFraseResponsive}`}>“A terapia começa antes mesmo <br></br> do terapeuta. Quando a pessoa percebe <br></br> que algo precisa ser mudado, <br></br> já é parte do processo.”</p>
                            <p className={`mb-0 ${styles.sobreMimFraseResponsiveMini}`}>“A terapia começa antes mesmo do terapeuta. Quando a pessoa percebe <br></br> que algo precisa ser mudado, <br></br> já é parte do processo.”</p>
                        </blockquote>
                        <figcaption className={`blockquote-footer ${styles.sobreMimNomeAutor}`}>
                            Ricardo Furtado
                        </figcaption>
                        <figcaption className={`blockquote-footer ${styles.sobreMimNomeAutorMini}`}>
                            Ricardo Furtado
                        </figcaption>
                        </figure>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default SobreMim;