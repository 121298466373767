
const FooterBar = () => {

  const handlRedirectLinkedin = () => {
    window.open('https://www.linkedin.com/in/ana-caroline-almeida-8313a880/', '_blank', 'noreferrer');
  }

  const handlRedirectEmail = () => {
    window.open('mailto:carolinealmeida.c@gmail.com', '_blank', 'noreferrer');
  }

  return (
      <footer className="text-center text-lg-start bg-light text-muted">
        <section className="p-4 border-bottom" style={{ backgroundColor : "rgba(0, 0, 0, 0.05)"}}>
          <div className="d-flex flex-row-reverse">
            <button type="button" className="btn btn-link me-4 text-reset" onClick={handlRedirectEmail} style={{textDecoration: 'none' }}>
              <i className="fab fa-google" style={{ fontSize: '1.25rem'}}></i>
            </button>
            <button type="button" className="btn btn-link text-reset" onClick={handlRedirectLinkedin} style={{textDecoration: 'none' }}>
              <i className="fab fa-linkedin" style={{ fontSize: '1.25rem'}}></i>
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <span>Ana Caroline Almeida. Psicóloga Clínica e Perinatal (CRP ​03/11502).</span>
          </div>
        </section>
      </footer>
  );
};

export default FooterBar;