
const Instrucoes = () => {

    return (
        <>
            <div className="card">
                <div className="card-body" style={{ color: '#ff9898' }}>
                    <div style={{ marginTop: '1.25rem' }}>
                        <h5 style={{ color: '#737373' }}>
                            Veja abaixo como é simples agendar a sua consulta:
                        </h5>
                    </div>
                    <div style={{ marginTop: '1.25rem' }}>
                        <i className="fa-solid fa-check" style={{ color: '#737373', verticalAlign: 'baseline' }}></i>
                        <span style={{ fontSize: '1rem', color: '#737373', marginLeft: '1rem' }}>
                            PASSO 1 – Entre em contato com a psicóloga pelo whatsapp para agendar.
                        </span>
                    </div>
                    <div style={{ marginTop: '1.25rem' }}>
                        <i className="fa-solid fa-check" style={{ color: '#737373', verticalAlign: 'baseline' }}></i>
                        <span style={{ fontSize: '1rem', color: '#737373', marginLeft: '1rem' }}>
                            PASSO 2 – Escolham data e horário melhor para ambos.
                        </span>
                    </div>
                    <div style={{ marginTop: '1.25rem' }}>
                        <i className="fa-solid fa-check" style={{ color: '#737373', verticalAlign: 'baseline' }}></i>
                        <span style={{ fontSize: '1rem', color: '#737373', marginLeft: '1rem' }}>
                            PASSO 3 – Efetue o pagamento (Pix, boleto ou transferência).
                        </span>
                    </div>
                    <div style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
                        <i className="fa-solid fa-check" style={{ color: '#737373', verticalAlign: 'baseline' }}></i>
                        <span style={{ fontSize: '1rem', color: '#737373', marginLeft: '1rem' }}>
                            PASSO 4 – Recebimento da confirmação do atendimento.
                        </span>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Instrucoes;