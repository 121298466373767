import '@fortawesome/fontawesome-free/css/all.min.css';
import styles from '../components/Contato.module.css';

const Contato = () => {

    return (
        <>
            <div className="card">
                <div className="card-body" style={{ color: '#ff9898' }}>
                    <div style={{ marginTop: '1.25rem' }}>
                        <h5 style={{ color: '#737373' }}>
                            Informações e Agendamento:
                        </h5>
                    </div>
                    <div style={{ marginTop: '1.25rem' }}>
                        <i className="fa-brands fa-whatsapp" style={{ fontSize: '2rem', color: '#737373', verticalAlign: 'baseline' }}></i>
                        <span style={{ fontSize: '1.5rem', color: '#737373', marginLeft: '1rem' }}>
                            <strong>(71) 99188-7375 </strong>
                        </span>
                    </div>
                    <div style={{ marginTop: '1.25rem', marginBottom: '1.25rem' }}>
                        <i className={`fa-regular fa-envelope ${styles.icon}`}></i>
                        <span className={`${styles.email}`}>
                            carolinealmeida.c@gmail.com
                        </span>
                    </div>
                </div>
            </div>
        </>
    );

}

export default Contato;