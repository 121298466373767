import styles from '../components/Card.module.css';

type CardProps = {
    title: string | null;
    subTitle: string | null;
    descricao: string | null;
}

const Card = (cardParams: CardProps) => {

    return (
        <>
            <div className={`card ${styles.cardMod}`}>
                <div className="card-body">
                <h5 className={`card-title ${styles.cardTitle}`}>{cardParams.title}</h5>
                <h6 className={`card-subtitle mb-2 text-muted ${styles.cardSubtitle}`}>{cardParams.subTitle}</h6>
                <p className={`card-text ${styles.cardText}`}>{cardParams.descricao}</p>
                </div>
            </div>
            <div className={`card ${styles.cardModResponsive}`}>
                <div className="card-body">
                <h5 className={`card-title ${styles.cardTitleResponsive}`}>{cardParams.title}</h5>
                <h6 className={`card-subtitle mb-2 text-muted ${styles.cardSubtitleResponsive}`}>{cardParams.subTitle}</h6>
                <p className={`card-text ${styles.cardTextResponsive}`}>{cardParams.descricao}</p>
                </div>
            </div>
        </>
        
    );

}

export default Card;