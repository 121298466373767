import { useState } from 'react';

type AccordionProps = {
    title1: string | null;
    descricao1: string | null;
    title2: string | null;
    descricao2: string | null;
    title3: string | null;
    descricao3: string | null;
    title4: string | null;
    descricao4: string | null;
    title5: string | null;
    descricao5: string | null;
}

const Accordion = (accordionParams: AccordionProps) => {

    const [accordionButton1, setAccordionButton1] = useState("accordion-button collapsed");
    const [accordionButton2, setAccordionButton2] = useState("accordion-button collapsed");
    const [accordionButton3, setAccordionButton3] = useState("accordion-button collapsed");
    const [accordionButton4, setAccordionButton4] = useState("accordion-button collapsed");
    const [accordionButton5, setAccordionButton5] = useState("accordion-button collapsed");
    const [ariaExpanded1, setAriaExpanded1] = useState(false);
    const [ariaExpanded2, setAriaExpanded2] = useState(false);
    const [ariaExpanded3, setAriaExpanded3] = useState(false);
    const [ariaExpanded4, setAriaExpanded4] = useState(false);
    const [ariaExpanded5, setAriaExpanded5] = useState(false);
    const [accordionCollapse1, setAccordionCollapse1] = useState("accordion-collapse collapse");
    const [accordionCollapse2, setAccordionCollapse2] = useState("accordion-collapse collapse");
    const [accordionCollapse3, setAccordionCollapse3] = useState("accordion-collapse collapse");
    const [accordionCollapse4, setAccordionCollapse4] = useState("accordion-collapse collapse");
    const [accordionCollapse5, setAccordionCollapse5] = useState("accordion-collapse collapse");


    const closeState = () => {
        setAccordionButton1("accordion-button collapsed");
        setAccordionButton2("accordion-button collapsed");
        setAccordionButton3("accordion-button collapsed");
        setAccordionButton4("accordion-button collapsed");
        setAccordionButton5("accordion-button collapsed");
        setAriaExpanded1(false);
        setAriaExpanded2(false);
        setAriaExpanded3(false);
        setAriaExpanded4(false);
        setAriaExpanded5(false);
        setAccordionCollapse1("accordion-collapse collapse");
        setAccordionCollapse2("accordion-collapse collapse");
        setAccordionCollapse3("accordion-collapse collapse");
        setAccordionCollapse4("accordion-collapse collapse");
        setAccordionCollapse5("accordion-collapse collapse");
    }

    const configuraState = (value: string) => {

        if (value === '') {
          return value
        } else if (value === 'filtro1') {
            if (ariaExpanded1 === true) {
                closeState();
            } else {
                setAccordionButton1("accordion-button");
                setAccordionButton2("accordion-button collapsed");
                setAccordionButton3("accordion-button collapsed");
                setAccordionButton4("accordion-button collapsed");
                setAccordionButton5("accordion-button collapsed");
                setAriaExpanded1(true);
                setAriaExpanded2(false);
                setAriaExpanded3(false);
                setAriaExpanded4(false);
                setAriaExpanded5(false);
                setAccordionCollapse1("accordion-collapse collapse show");
                setAccordionCollapse2("accordion-collapse collapse");
                setAccordionCollapse3("accordion-collapse collapse");
                setAccordionCollapse4("accordion-collapse collapse");
                setAccordionCollapse5("accordion-collapse collapse");
            }
        } else if (value === 'filtro2') {
            if (ariaExpanded2 === true) {
                closeState();
            } else {
                setAccordionButton1("accordion-button collapsed");
                setAccordionButton2("accordion-button");
                setAccordionButton3("accordion-button collapsed");
                setAccordionButton4("accordion-button collapsed");
                setAccordionButton5("accordion-button collapsed");
                setAriaExpanded1(false);
                setAriaExpanded2(true);
                setAriaExpanded3(false);
                setAriaExpanded4(false);
                setAriaExpanded5(false);
                setAccordionCollapse1("accordion-collapse collapse");
                setAccordionCollapse2("accordion-collapse collapse show");
                setAccordionCollapse3("accordion-collapse collapse");
                setAccordionCollapse4("accordion-collapse collapse");
                setAccordionCollapse5("accordion-collapse collapse");
            }
        } else if (value === 'filtro3') {
            if (ariaExpanded3 === true) {
                closeState();
            } else {
                setAccordionButton1("accordion-button collapsed");
                setAccordionButton2("accordion-button collapsed");
                setAccordionButton3("accordion-button");
                setAccordionButton4("accordion-button collapsed");
                setAccordionButton5("accordion-button collapsed");
                setAriaExpanded1(false);
                setAriaExpanded2(false);
                setAriaExpanded3(true);
                setAriaExpanded4(false);
                setAriaExpanded5(false);
                setAccordionCollapse1("accordion-collapse collapse");
                setAccordionCollapse2("accordion-collapse collapse");
                setAccordionCollapse3("accordion-collapse collapse show");
                setAccordionCollapse4("accordion-collapse collapse");
                setAccordionCollapse5("accordion-collapse collapse");
            }
        } else if (value === 'filtro4') {
            if (ariaExpanded4 === true) {
                closeState();
            } else {
                setAccordionButton1("accordion-button collapsed");
                setAccordionButton2("accordion-button collapsed");
                setAccordionButton3("accordion-button collapsed");
                setAccordionButton4("accordion-button");
                setAccordionButton5("accordion-button collapsed");
                setAriaExpanded1(false);
                setAriaExpanded2(false);
                setAriaExpanded3(false);
                setAriaExpanded4(true);
                setAriaExpanded5(false);
                setAccordionCollapse1("accordion-collapse collapse");
                setAccordionCollapse2("accordion-collapse collapse");
                setAccordionCollapse3("accordion-collapse collapse");
                setAccordionCollapse4("accordion-collapse collapse show");
                setAccordionCollapse5("accordion-collapse collapse");
            }
        } else if (value === 'filtro5') {
            if (ariaExpanded5 === true) {
                closeState();
            } else {
                setAccordionButton1("accordion-button collapsed");
                setAccordionButton2("accordion-button collapsed");
                setAccordionButton3("accordion-button collapsed");
                setAccordionButton4("accordion-button collapsed");
                setAccordionButton5("accordion-button");
                setAriaExpanded1(false);
                setAriaExpanded2(false);
                setAriaExpanded3(false);
                setAriaExpanded4(false);
                setAriaExpanded5(true);
                setAccordionCollapse1("accordion-collapse collapse");
                setAccordionCollapse2("accordion-collapse collapse");
                setAccordionCollapse3("accordion-collapse collapse");
                setAccordionCollapse4("accordion-collapse collapse");
                setAccordionCollapse5("accordion-collapse collapse show");
            }
        }
      }

    return (
        
        <div className="accordion" id="accordionExample">
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
            <button className={accordionButton1} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded={ariaExpanded1} aria-controls="collapseOne" style={{ color: '#737373' }} onClick={() => configuraState('filtro1')}>
                <strong>{accordionParams.title1}</strong>
            </button>
            </h2>
            <div id="collapseOne" className={accordionCollapse1} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body" style={{ color: '#737373', textAlign: 'justify' }}>
                {accordionParams.descricao1}
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
            <button className={accordionButton2} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded={ariaExpanded2} aria-controls="collapseTwo" style={{ color: '#737373' }} onClick={() => configuraState('filtro2')}>
                <strong>{accordionParams.title2}</strong>
            </button>
            </h2>
            <div id="collapseTwo" className={accordionCollapse2} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body" style={{ color: '#737373', textAlign: 'justify' }}>
                {accordionParams.descricao2}
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
            <button className={accordionButton3} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded={ariaExpanded3} aria-controls="collapseThree" style={{ color: '#737373' }} onClick={() => configuraState('filtro3')}>
                <strong>{accordionParams.title3}</strong>
            </button>
            </h2>
            <div id="collapseThree" className={accordionCollapse3} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body" style={{ color: '#737373', textAlign: 'justify' }}>
                {accordionParams.descricao3}
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
            <button className={accordionButton4} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded={ariaExpanded4} aria-controls="collapseFour" style={{ color: '#737373' }} onClick={() => configuraState('filtro4')}>
                <strong>{accordionParams.title4}</strong>
            </button>
            </h2>
            <div id="collapseFour" className={accordionCollapse4} aria-labelledby="headingFour" data-bs-parent="#accordionExample">
            <div className="accordion-body" style={{ color: '#737373', textAlign: 'justify' }}>
                {accordionParams.descricao4}
            </div>
            </div>
        </div>
        <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
            <button className={accordionButton5} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded={ariaExpanded5} aria-controls="collapseFive" style={{ color: '#737373' }} onClick={() => configuraState('filtro5')}>
                <strong>{accordionParams.title5}</strong>
            </button>
            </h2>
            <div id="collapseFive" className={accordionCollapse5} aria-labelledby="headingFive" data-bs-parent="#accordionExample">
            <div className="accordion-body" style={{ color: '#737373', textAlign: 'justify' }}>
                {accordionParams.descricao5}
            </div>
            </div>
        </div>
        </div>
    );

}

export default Accordion;