import React from 'react';

import 'bootswatch/dist/simplex/bootstrap.css';
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import { Grid } from "@material-ui/core";
import NavBar from '../src/components/NavBar';
import SobreMim from '../src/components/SobreMim';
import Card from '../src/components/Card';
import Instrucoes from '../src/components/Instrucoes';
import Accordion from '../src/components/Accordion';
import Contato from '../src/components/Contato';
import FooterBar from '../src/components/FooterBar';
import imageFinal from '../src/images/apresentacao.png';
import imageFinalResponsiva from '../src/images/apresentacaoResponsiva.png';
import imageSobre from '../src/images/sobreMod.jpeg';
import atendimento from '../src/images/atendimento.png';
import styles from '../src/App.module.css';

function App() {

  return (
    <div className="App">
      <NavBar />
      <Grid className={`${styles.home}`} container spacing={4}>
        <Grid container item spacing={2}>
          <Grid className={`${styles.apresentacao}`} item xs={12}>
            <img id={'home'} style={{ width: '100%' }} src={imageFinal} alt='logo' />
          </Grid>
          <Grid className={`${styles.apresentacaoResponsiva}`} item xs={12}>
            <img id={'home'} style={{ width: '100%' }} src={imageFinalResponsiva} alt='logo' />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: '-1rem', marginTop: '1rem', backgroundColor: 'white'}}>
        <Grid className={`${styles.sobreMim}`} container item spacing={4} id={'sobreMim'}>
          <Grid className={`${styles.sobreMimGridImage}`} item xs={2}>
            <img style={{ width: '100%' }} src={imageSobre} alt='logo' />
          </Grid>
          <Grid className={`${styles.sobreMimGridImageResponsive}`} item xs={12}>
            <img style={{ width: '40%' }} src={imageSobre} alt='logo' />
          </Grid>
          <Grid className={`${styles.sobreMimGrid}`}item xs={7}>
            <SobreMim />
          </Grid>
          <Grid className={`${styles.sobreMimGridResponsive}`}item xs={12}>
            <SobreMim />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: '-1rem', marginTop: '1rem', backgroundColor: '#fff5f5' }}>
        <Grid container item spacing={4}>
          <Grid className={`${styles.especialidadesTitle}`} item xs={12}>
            <h2 style={{ color: '#737373' }} id={'especialidades'}>Especialidades</h2>
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidades}`} container item spacing={4}>
          <Grid item xs={3} style={{ marginLeft: '10%' }}>
            <Card 
              title={'Psicoterapia Individual'}
              subTitle={''}
              descricao={'Atendimento semanal para adultos e adolescentes (a partir dos 12 anos).'}
            />
          </Grid>
          <Grid item xs={3}>
            <Card 
              title={'Avaliação Psicológica'}
              subTitle={''}
              descricao={'Avaliação com testes psicológicos para coletar dados, levantar hipóteses diagnósticas e verificar o tratamento mais adequado para o paciente.'}
            />
          </Grid>
          <Grid item xs={3}>
            <Card 
              title={'Psicoterapia para Gestantes'}
              subTitle={''}
              descricao={'Atendimento especializado para gestantes, tentantes e Pais.'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidades}`} container item spacing={4} style={{ marginBottom: '1rem' }}>
          <Grid item xs={3} style={{ marginLeft: '10%' }}>
            <Card 
              title={'Avaliação para Bariátrica'}
              subTitle={''}
              descricao={'Avaliação com objetivo de verificar o quanto o paciente está apto para a cirurgia, bem como suporte e cuidados no pós-operatório.'}
            />
          </Grid>
          <Grid item xs={3}>
            <Card
              title={'Psicoterapia com foco no Emagrecimento'}
              subTitle={''}
              descricao={'Psicoterapia voltada para a dificuldade de perder peso, devido a questões de ordem emocional (compulsão, ansiedade, dentre outros).'}
            />
          </Grid>
          <Grid item xs={3}>
            <Card
              title={'Orientação Profissional'}
              subTitle={''}
              descricao={'Atendimento com objetivo de compreender aptidões, interesses e dificuldades que envolvem os processos de uma escolha profissional.'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidadesResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%' }}>
            <Card 
              title={'Psicoterapia Individual'}
              subTitle={''}
              descricao={'Atendimento semanal para adultos e adolescentes (a partir dos 12 anos).'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidadesResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%' }}>
          <Card 
              title={'Avaliação Psicológica'}
              subTitle={''}
              descricao={'Avaliação com testes psicológicos para coletar dados, levantar hipóteses diagnósticas e verificar o tratamento mais adequado para o paciente.'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidadesResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%' }}>
            <Card 
              title={'Psicoterapia para Gestantes'}
              subTitle={''}
              descricao={'Atendimento especializado para gestantes, tentantes e Pais.'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidadesResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%' }}>
          <Card 
              title={'Avaliação para Bariátrica'}
              subTitle={''}
              descricao={'Avaliação com objetivo de verificar o quanto o paciente está apto para a cirurgia, bem como suporte e cuidados no pós-operatório.'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidadesResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%' }}>
            <Card
              title={'Psicoterapia com foco no Emagrecimento'}
              subTitle={''}
              descricao={'Psicoterapia voltada para a dificuldade de perder peso, devido a questões de ordem emocional (compulsão, ansiedade, dentre outros).'}
            />
          </Grid>
        </Grid>
        <Grid className={`${styles.especialidadesResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%', marginBottom: '5%' }}>
            <Card
              title={'Orientação Profissional'}
              subTitle={''}
              descricao={'Atendimento com objetivo de compreender aptidões, interesses e dificuldades que envolvem os processos de uma escolha profissional.'}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: '-1rem', marginTop: '1rem', backgroundColor: 'white' }}>
        <Grid container item spacing={4}>
          <Grid className={`${styles.duvidasTitle}`} item xs={12}>
            <h2 style={{ color: '#737373' }}>
              <Grid container spacing={4}>
                <Grid container item spacing={4}>
                  <Grid className={`${styles.duvidasGridTitle}`} item id={'duvidas'}>
                    <span>
                      Dúvidas e Perguntas Frequentes
                    </span>
                  </Grid>
                  <Grid className={`${styles.duvidasGridTitleResponsive}`} item id={'duvidas'}>
                    <span>
                      Dúvidas e Perguntas <br></br>
                      Frequentes
                    </span>
                  </Grid>
                  <Grid item>
                    <i className="pi pi-question-circle" style={{ fontSize: '2.5rem' }}></i>
                  </Grid>
                </Grid>
              </Grid>
            </h2>
          </Grid>
        </Grid>
        <Grid className={`${styles.duvidas}`} container item spacing={4}>
          <Grid className={`${styles.duvidasGrid}`} item xs={6}>
            <Accordion
              title1={'Como funciona o atendimento online?'}
              descricao1={'Essa modalidade é regulamentada pelo Conselho Federal de Psicologia e possui a mesma eficácia do atendimento presencial, vários estudos já comprovaram que ambos têm a mesma qualidade. Os atendimentos ocorrem uma vez por semana, em sessões de 40 minutos, através de videochamadas pelo WhatsApp no horário agendado. A ética, o sigilo e a aplicação das técnicas mantém-se iguais às práticas do atendimento presencial.'}
              title2={'Quais os benefícios do atendimento online?'}
              descricao2={'A psicoterapia online pode ser realizada no conforto da sua casa ou escritório, ter flexibilidade de horários, não perder tempo com deslocamento e engarrafamentos até o local do consultório, sem contar no fácil acesso, podendo estar em qualquer cidade do Brasil ou no Exterior.'}
              title3={'Atende pelo plano de saúde?'}
              descricao3={'Não. Somente particular, para informações sobre valores e pacotes de descontos entre com contato pelo whatsapp.'}
              title4={'Quais são as formas de pagamento?'}
              descricao4={'O pagamento pode ser realizado por pix, transferência ou boleto bancário e poderá ser efetuado até 4h antes do horário agendado.'}
              title5={'Instruções importantes para o atendimento'}
              descricao5={'Recomenda-se que no momento da sessão, o paciente utilize fones de ouvido, tenha uma internet com boa conexão e escolha um local confortável, sem barulho e privado (não é autorizado a participação de terceiros) para manter a confidencialidade das sessões, e sem interrupções externas. Se caso houver alguma falha no serviço, como a perda de conexão com o provedor, o atendimento será reagendado para outro dia e horário sem ônus algum.'} 
            />
          </Grid>
          <Grid className={`${styles.duvidasGridResponsive}`} item xs={10}>
            <Accordion
              title1={'Como funciona o atendimento online?'}
              descricao1={'Essa modalidade é regulamentada pelo Conselho Federal de Psicologia e possui a mesma eficácia do atendimento presencial, vários estudos já comprovaram que ambos têm a mesma qualidade. Os atendimentos ocorrem uma vez por semana, em sessões de 40 minutos, através de videochamadas pelo WhatsApp no horário agendado. A ética, o sigilo e a aplicação das técnicas mantém-se iguais às práticas do atendimento presencial.'}
              title2={'Quais os benefícios do atendimento online?'}
              descricao2={'A psicoterapia online pode ser realizada no conforto da sua casa ou escritório, ter flexibilidade de horários, não perder tempo com deslocamento e engarrafamentos até o local do consultório, sem contar no fácil acesso, podendo estar em qualquer cidade do Brasil ou no Exterior.'}
              title3={'Atende pelo plano de saúde?'}
              descricao3={'Não. Somente particular, para informações sobre valores e pacotes de descontos entre com contato pelo whatsapp.'}
              title4={'Quais são as formas de pagamento?'}
              descricao4={'O pagamento pode ser realizado por pix, transferência ou boleto bancário e poderá ser efetuado até 4h antes do horário agendado.'}
              title5={'Instruções importantes para o atendimento'}
              descricao5={'Recomenda-se que no momento da sessão, o paciente utilize fones de ouvido, tenha uma internet com boa conexão e escolha um local confortável, sem barulho e privado (não é autorizado a participação de terceiros) para manter a confidencialidade das sessões, e sem interrupções externas. Se caso houver alguma falha no serviço, como a perda de conexão com o provedor, o atendimento será reagendado para outro dia e horário sem ônus algum.'} 
            />
          </Grid>
          <Grid className={`${styles.duvidasGridImage}`} item xs={3}>
            <img style={{ width: '100%', height: '18.625rem' }} src={atendimento} alt='atendimento' />
          </Grid>
          <Grid className={`${styles.duvidasGridImageResponsive}`} item xs={12}>
            <img style={{ width: '50%', height: 'auto' }} src={atendimento} alt='atendimento' />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: '-1rem', marginTop: '1rem', backgroundColor: '#fff5f5' }}>
        <Grid container item spacing={4}>
          <Grid className={`${styles.contatosTitle}`} item xs={6}>
            <h2 style={{ color: '#737373' }} id={'contato'}>Contatos</h2>
          </Grid>
        </Grid>
        <Grid className={`${styles.contatos}`} container item spacing={4}>
          <Grid item xs={5} style={{ marginLeft: '10%' }}>
            <Instrucoes />
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'justify' }}>
            <Contato />
          </Grid>
        </Grid>
        <Grid className={`${styles.contatosResponsive}`} container item spacing={4}>
          <Grid item xs={10} style={{ marginLeft: '8%' }}>
            <Instrucoes />
          </Grid>
          <Grid item xs={10} style={{ marginLeft: '8%', textAlign: 'justify' }}>
            <Contato />
          </Grid>
        </Grid>
      </Grid>
      <FooterBar />
    </div>
  );
}

export default App;
